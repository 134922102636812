import { axiosInstance } from "./general";

export const fetchUser = async () => {
  const response = await axiosInstance.get(`/auth/user`);
  console.log("User: ", response.data);
  return response.data;
};

export const fetchAllUsers = async () => {
  const response = await axiosInstance.get(`/auth/all_users`);
  return response.data;
};

export const onboardUser = async () => {
  const response = await axiosInstance.post(`/auth/onboard`, {});
  return response;
};

export const pollIsNew = async () => {
  const response = await axiosInstance.get(`/auth/is_new`);
  return response;
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post(`/auth/logout`, {});
    return response.data;
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
};

export const pauseAccountAPI = async (pause) => {
  const response = await axiosInstance.post(`/auth/pause`, { pause });
  return response.data;
};

export const isLoggedIn = async () => {
  const response = await axiosInstance.get(`/auth/is_logged_in`);
  return response.data.is_logged_in;
};
