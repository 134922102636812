import { useRef } from "react";
import { Box, Button, Fade, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, deformatCurrency } from "../../../utils/formUtils";

function TableArtifact({
  artifact,
  sendMessage,
  chatbotLoading,
  setModalOpen,
}) {
  const dataGridRef = useRef(null);

  // Prepare columns for DataGrid
  const columns = artifact.columns.map((column, colIdx) => {
    let flexValue = 1; // Default flex
    if (
      column.width &&
      typeof column.width === "string" &&
      column.width.endsWith("%")
    ) {
      const percentage = parseFloat(column.width);
      flexValue = isNaN(percentage) ? 1 : percentage;
    }

    const baseColumn = {
      field: column.name,
      headerName: column.name,
      flex: flexValue,
      minWidth: 100,
      headerClassName: "table-header",
    };

    if (column.type === "message_button") {
      return {
        ...baseColumn,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ my: 1, width: "50%" }}>
            <Button
              onClick={() => {
                if (!chatbotLoading) {
                  sendMessage(params.value.message, []);
                }
                setModalOpen(false);
              }}
              variant="contained"
              color="primary"
              size="small"
              sx={{ width: "100%", py: 0 }}
            >
              {params.value.label}
            </Button>
          </Box>
        ),
      };
    } else {
      return {
        ...baseColumn,
        sortable: true,
        renderCell:
          column.type === "text"
            ? (params) => (
                <Box sx={{ my: 0 }}>
                  <span dangerouslySetInnerHTML={{ __html: params.value }} />
                </Box>
              )
            : undefined,
      };
    }
  });

  // Prepare rows for DataGrid
  const rows = artifact.rows.map((row, rowIdx) => {
    const rowData = { id: rowIdx };
    row.forEach((cell, cellIdx) => {
      const column = artifact.columns[cellIdx];
      if (column.type === "message_button") {
        rowData[column.name] = { message: cell.message, label: cell.label };
      } else {
        if (column.type === "number" && column.currency) {
          rowData[column.name] = formatCurrency(cell);
        } else {
          rowData[column.name] = cell;
        }
      }
    });
    return rowData;
  });

  // 1. Identify numeric columns and calculate sums
  const numericColumns = artifact.columns.filter(
    (col) => col.type === "number"
  );
  const sums = {};
  numericColumns.forEach((col) => {
    sums[col.name] = 0;
  });

  // 2. Go through each row's numeric values and accumulate
  rows.forEach((rowData) => {
    numericColumns.forEach((col) => {
      const value = parseFloat(deformatCurrency(rowData[col.name])) || 0;
      sums[col.name] += value;
    });
  });

  // 3. Create a total row (only if there's at least one numeric column)
  if (numericColumns.length > 0) {
    const totalRow = {
      id: "totalsRow",
    };
    artifact.columns.forEach((col, idx) => {
      if (col.type === "number") {
        // Place the summed value in numeric columns
        totalRow[col.name] = col.currency
          ? formatCurrency(sums[col.name])
          : sums[col.name];
      } else {
        // For non-numeric columns, you can display a label in the first column
        totalRow[col.name] = idx === 0 ? "TOTAL" : "";
      }
    });
    rows.push(totalRow);
  }

  console.log("numericColumns:", numericColumns);
  console.log("sums:", sums);
  console.log("rows before totals row:", rows);
  console.log("rows after pushing totals row:", rows);

  return (
    <Fade in={true} timeout={300}>
      <Box sx={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.main",
          }}
        >
          {artifact.title}
        </Typography>
        <div style={{ height: "80vh" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowHeight={() => "auto"}
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            sx={{
              "& .table-header": {
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
              },
            }}
          />
        </div>
      </Box>
    </Fade>
  );
}

export default TableArtifact;
