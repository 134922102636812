import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Task,
  Logout,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  Home as HomeIcon,
} from "@mui/icons-material"; // Import icons

import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

const DrawerComponent = ({
  drawerOpen,
  toggleDrawer,
  user,
  handlePauseToggle,
  handleLogout,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Define the drawer items with their respective routes
  const drawerItems = [
    {
      text: "Home",
      icon: <HomeIcon />,
      route: "/dashboard/home",
      onClick: () => navigate("/dashboard/home"),
      isNavigation: true,
    },
    {
      text: "All Requests",
      icon: <DashboardIcon />,
      route: "/dashboard/all_requests",
      onClick: () => navigate("/dashboard/all_requests"),
      isNavigation: true,
    },

    ...(user?.roles?.some((r) => r.name === "admin")
      ? [
          {
            text: "Settings",
            icon: <SettingsIcon />,
            route: "/dashboard/settings",
            onClick: () => navigate("/dashboard/settings"),
            isNavigation: true,
          },
        ]
      : []),
    {
      text: "Logout",
      icon: <Logout />,
      route: "/logout",
      onClick: handleLogout,
      isNavigation: false,
      isLogout: true,
    },
  ];

  return (
    <>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          p: 0,
        }}
      >
        {user && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: 250,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                px: 2,
                py: 2,
                width: "100%",
              }}
            >
              <Avatar sx={{ mr: 2 }}>{user.name.charAt(0)}</Avatar>
              <Typography
                variant="body1"
                sx={{
                  color: "text.disabled",
                }}
              >
                {user.name}
              </Typography>
            </Box>

            <Divider sx={{ width: "100%" }} />

            <List sx={{ width: "100%" }}>
              {drawerItems.map((item) => {
                const isSelected =
                  item.isNavigation && location.pathname === item.route;

                return (
                  <ListItem key={item.text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        item.onClick();
                        if (item.isNavigation) {
                          toggleDrawer(false)(); // Close drawer after navigation
                        }
                      }}
                      selected={isSelected}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "background.dark",
                          color: "primary.main",
                          "& .MuiListItemIcon-root": {
                            color: "primary.main",
                          },
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: isSelected ? "primary.main" : "text.primary",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
