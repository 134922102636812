import { useState } from "react";
import FormDisplay from "../../FormDisplay";
import {
  Box,
  Button,
  Modal,
  Typography,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { createERPContract } from "../../../api/request";

const FormArtifact = ({ artifact, sendMessage, chatbotLoading }) => {
  const [formConfig, setFormConfig] = useState(artifact.sections);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogColor, setDialogColor] = useState(null);
  const [loading, setLoading] = useState(false);

  // New state for confirmation dialog
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [proceedWithCreation, setProceedWithCreation] = useState(false);

  function setIsModified(isModified) {
    console.log(isModified);
  }

  function handleFormUpdate(formData) {
    console.log(formData);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  // Function to handle the actual contract creation
  const createContract = async () => {
    setLoading(true);
    try {
      console.log("Creating contract for artifact:", artifact);
      setDialogTitle("Creating contract...");
      setDialogMessage(null);
      setDialogColor("info");
      const response = await createERPContract(artifact.request_id, formConfig);
      console.log("Contract created successfully:", response);
      setDialogTitle("Contract created successfully!");
      setDialogMessage({
        __html: response.message,
      });
      setDialogColor("success");

      // Refresh the page after 5 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error creating contract:", error);
      setDialogTitle("Error creating contract");
      let errs = error.response?.data?.error || "An unexpected error occurred.";

      setDialogMessage({ __html: errs });

      setDialogColor("error");
    } finally {
      setLoading(false);
      setOpen(true);
    }
  };

  // Updated handleDraftContract to use confirmation dialog
  const handleDraftContract = () => {
    if (artifact.existing_contract_id) {
      setConfirmOpen(true);
    } else {
      createContract();
    }
  };

  // Handlers for confirmation dialog
  const handleConfirm = () => {
    setConfirmOpen(false);
    createContract();
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  let buttons = null;
  if (true) {
    buttons = (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDraftContract}
          disabled={loading}
        >
          {loading ? (
            <>
              <CircularProgress size={24} color="inherit" sx={{ mr: 2 }} />
              Creating contract...
            </>
          ) : (
            "Create PO"
          )}
        </Button>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          px: 0,
          pb: 2,
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <FormDisplay
          formConfig={formConfig}
          sendMessage={sendMessage}
          chatbotLoading={chatbotLoading || loading}
          conversationOver={false}
          setFormConfig={setFormConfig}
          handleFormUpdate={handleFormUpdate}
          setIsModified={setIsModified}
          inverseBackground={false}
          isSmall={true}
        />
        {buttons}
        {/* Status Dialog */}
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          sx={{ p: 10, minWidth: 500 }}
        >
          <Alert severity={dialogColor} sx={{ p: 2 }}>
            <AlertTitle>{dialogTitle}</AlertTitle>
            <span dangerouslySetInnerHTML={dialogMessage} />
          </Alert>
        </Dialog>
        {/* Confirmation Dialog */}
        <Dialog
          open={confirmOpen}
          onClose={handleCancel}
          aria-describedby="confirm-dialog-description"
        >
          <Alert severity="warning" sx={{ px: 2, pt: 2 }}>
            <AlertTitle>Confirm Overwrite</AlertTitle>
            An existing contract is already created for this request. Are you
            sure you want to create a new contract and de-link the existing one?
            <DialogActions sx={{ mb: 0 }} flex justifyContent="space-between">
              <Button onClick={handleCancel} color="primary">
                No, keep the existing contract
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                Yes, create a new contract
              </Button>
            </DialogActions>
          </Alert>
        </Dialog>
      </Box>
    </>
  );
};

export default FormArtifact;
