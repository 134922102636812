import React, { useState, useEffect } from "react";
import {
  Handle,
  Position,
  NodeToolbar,
  // if you are using @reactflow/node-toolbar, then import from that package
} from "@xyflow/react";
import {
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  Modal,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/DoneRounded";
import InProgressIcon from "@mui/icons-material/HourglassEmptyRounded";
import NewIcon from "@mui/icons-material/FiberNewRounded";
import CancelIcon from "@mui/icons-material/DeleteForeverOutlined";
import BypassIcon from "@mui/icons-material/AirlineStopsRounded";
import UnknownIcon from "@mui/icons-material/HelpRounded";
import RejectedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { getItemArtifacts, updateAgendaItem } from "../../api/request";
import Artifact from "../Chatbot/Artifacts/Artifact";
import { translateOptions, translateIcon } from "../../utils/formatting";
import CloseIcon from "@mui/icons-material/CloseRounded";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

function StepDetailsNode({ id, data }) {
  const theme = useTheme();

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [artifactContent, setArtifactContent] = useState(null);

  // const [fixedOptions, setFixedOptions] = useState([]);

  const [artifacts, setArtifacts] = useState([]);

  useEffect(() => {
    const fetchArtifacts = async () => {
      //   if (selected) {
      const { artifacts, options } = await getItemArtifacts(
        data.requestId,
        data.id
      );
      setArtifacts(artifacts);
      console.log("Updated options state:", artifacts);
      //   }
    };
    fetchArtifacts();
  }, [data.requestId, data.id]);

  // useEffect(() => {
  //   setFixedOptions(
  //     translateOptions(
  //       translateOptions([
  //         {
  //           description: "Approve",
  //           // icon: "CheckCircleIcon",
  //           color: "success",
  //           variant: "contained",
  //           message: `Mark ${data.title} as completed`,
  //         },
  //         {
  //           description: "Reject",
  //           // icon: "CancelIcon",
  //           color: "error",
  //           variant: "contained",
  //           message: `Mark ${data.title} as rejected`,
  //         },
  //       ])
  //     )
  //   );
  // }, [data.title]);
  // Introduce local state for approvers
  const [approvers, setApprovers] = useState(
    data.approvers ? data.approvers.map((a) => a.email) : []
  );

  // Update local state when data.approvers changes
  useEffect(() => {
    setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
  }, [data.approvers]);

  const getIcon = (icon, fontSize = 36) => {
    switch (icon) {
      case "new":
        return (
          <NewIcon
            sx={{ color: theme.palette.info.main, fontSize: fontSize }}
          />
        );
      case "in_progress":
        return (
          <InProgressIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      case "complete":
        return (
          <DoneIcon
            sx={{ color: theme.palette.success.main, fontSize: fontSize }}
          />
        );
      case "rejected":
        return (
          <RejectedIcon
            sx={{ color: theme.palette.error.main, fontSize: fontSize }}
          />
        );
      case "cancelled":
        return (
          <CancelIcon
            sx={{ color: theme.palette.error.main, fontSize: fontSize }}
          />
        );
      case "not_applicable":
        return (
          <BypassIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      case "unknown":
        return (
          <UnknownIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      default:
        return null;
    }
  };

  const handleStatusChange = async (event, newStatus) => {
    if (newStatus !== null) {
      try {
        await updateAgendaItem({
          requestId: data.requestId,
          agendaItemId: data.id,
          newStatus: newStatus,
        });
        data.getRequestDetails();
      } catch (error) {
        console.error("Error updating status:", error);
        const errorMessage =
          error.response?.data?.error ||
          "An unexpected error occurred while updating the status.";
        setError(errorMessage);
      }
    }
  };

  const handleApproverChange = async (event) => {
    const newApproversEmails = event.target.value.filter((email) =>
      email.includes("@")
    );

    console.log("newApproversEmails", newApproversEmails);

    // Optimistically update local state
    setApprovers(newApproversEmails);

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newAssignedUsersEmails: newApproversEmails,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating approvers:", error);
      const errorMessage =
        error.response?.data?.error ||
        "An unexpected error occurred while updating the approvers.";
      setError(errorMessage);

      // Revert local state if API call fails
      setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
    }
  };

  const handleDateChange = async (event) => {
    let newDate = event.target.value;

    // Extract the year from the newDate
    const year = new Date(newDate).getFullYear();

    // If the year is before 2010, set it to 2010
    if (year < 2010) {
      newDate = new Date(newDate).setFullYear(2010);
      newDate = new Date(newDate).toISOString().split("T")[0]; // Convert back to YYYY-MM-DD format
    }

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newDate: newDate,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating date:", error);
    }
  };

  const handleTaskChange = async (index, newDone) => {
    try {
      console.log("data.tasks", data.tasks);
      console.log("index", index);
      console.log("newDone", newDone);

      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newTasks: data.tasks.map((t, i) => ({
          ...t,
          done: i === index ? newDone : t.done,
        })),
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  // Optional: display extra content inside the node if needed
  const renderContent = () => {
    if (!data.content) return null;
    return data.content;
  };

  // For simplicity, this node just shows "DETAILS" plus the parent's title
  return (
    <Box>
      {/* 
       The NodeToolbar is only visible when 'selected' is true.
       Here you can place your extended controls like assigned-to (approvers), status, and tasks. 
      */}
      <Box className="nopan" position={Position.Bottom}>
        <Paper
          elevation={10}
          sx={{
            backgroundColor: "background.default",
            borderRadius: 1,
            padding: theme.spacing(2),
            boxShadow: theme.shadows[3],
            width: "100%",
            minWidth: "40rem",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            scale: 1.2,
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              mb: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {data.title ?? "Untitled Step"}
            <Box>
              {Object.keys(artifacts).map((key) => (
                <Button
                  key={key}
                  variant="contained"
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => {
                    setArtifactContent(artifacts[key]);
                    handleOpen();
                  }}
                >
                  {key}
                </Button>
              ))}
            </Box>
            <Box>
              <IconButton onClick={() => data.handleNodeClick(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 0 }}>
            <ToggleButtonGroup
              value={data.status || ""}
              exclusive
              onChange={handleStatusChange}
              size="small"
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <ToggleButton
                sx={{ width: "16.66%", flexDirection: "column" }}
                value="new"
              >
                {getIcon("new", 20)}
                <Typography variant="subtitle">New</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "16.66%", flexDirection: "column" }}
                value="in_progress"
              >
                {getIcon("in_progress", 20)}
                <Typography variant="subtitle">In Progress</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "16.66%", flexDirection: "column" }}
                value="complete"
              >
                {getIcon("complete", 20)}
                <Typography variant="subtitle">Complete</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "16.66%", flexDirection: "column" }}
                value="rejected"
              >
                {getIcon("rejected", 20)}
                <Typography variant="subtitle">Rejected</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "16.66%", flexDirection: "column" }}
                value="cancelled"
              >
                {getIcon("cancelled", 20)}
                <Typography variant="subtitle">Cancelled</Typography>
              </ToggleButton>
              <ToggleButton
                sx={{ width: "16.66%", flexDirection: "column" }}
                value="not_applicable"
              >
                {getIcon("not_applicable", 20)}
                <Typography variant="subtitle">Not Applicable</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <FormControl
            sx={{
              backgroundColor: "background.dark",
              borderRadius: "4px",
              height: "2rem",
            }}
            fullWidth
            size="small"
          >
            <TextField
              className="nodrag"
              label=""
              type="date"
              value={new Date(data.date).toISOString().split("T")[0]}
              onChange={handleDateChange}
              variant="standard"
              slotProps={{
                input: {
                  sx: {
                    pl: 1,
                    height: "100%",
                  },
                },
              }}
              size="small"
              sx={{ height: "100%" }}
            />
          </FormControl>
          {data.allUsers && (
            <FormControl
              sx={{
                backgroundColor: "background.dark",
                borderRadius: "4px",
                height: "2rem",
              }}
              fullWidth
              size="small"
            >
              <Select
                className="nodrag"
                variant="standard"
                size="small"
                multiple
                value={approvers}
                sx={{ height: "100%", pl: 1 }}
                displayEmpty
                onChange={handleApproverChange}
                renderValue={(selected) => {
                  if (selected.length === 0) return "Not assigned to Anyone";

                  const names =
                    selected
                      .map(
                        (userEmail) =>
                          data.allUsers.find((u) => u.email === userEmail)
                            ?.name || userEmail
                      )
                      .join(", ") || "";

                  return names;
                }}
              >
                {data.allUsers.map((user) => (
                  <MenuItem key={user.email} value={user.email} dense>
                    <Checkbox
                      checked={approvers.includes(user.email)}
                      sx={{
                        pt: 0.5,
                        pb: 0.5,
                        pl: 0.5,
                      }}
                    />
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box sx={{ display: "flex", gap: 2 }}>
            {data.tasks && (
              <Paper
                elevation={0}
                onWheelCapture={(evt) => {
                  evt.stopPropagation();
                  evt.nativeEvent.stopImmediatePropagation();
                }}
                sx={{
                  flex: 1,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", ml: 1, mb: 1 }}
                >
                  Tasks
                </Typography>
                <Box sx={{ width: "100%", border: "1px solid #e0e0e0" }}>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      maxHeight: "50vh",
                      overflowY: "auto",
                      py: 0,
                    }}
                  >
                    {data.tasks.map((task, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          task.action && (
                            <Tooltip
                              title={task.action.prompt}
                              placement="bottom"
                            >
                              <Button
                                onClick={() => {
                                  console.log(
                                    "task.action.prompt",
                                    task.action.prompt
                                  );
                                  data.sendMessage(task.action.prompt, []);
                                }}
                                variant="contained"
                                size="small"
                              >
                                <AutoFixHighOutlinedIcon sx={{ width: 20 }} />
                                <Typography
                                  variant="body2"
                                  sx={{ ml: 1, fontWeight: "bold" }}
                                >
                                  AI
                                </Typography>
                              </Button>
                            </Tooltip>
                          )
                        }
                        disablePadding
                        sx={{
                          my: 0,
                        }}
                      >
                        <ListItemButton
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            console.log("task.done", task.done);
                            handleTaskChange(index, !task.done);
                          }}
                        >
                          <ListItemText
                            primary={
                              <>
                                <Checkbox
                                  edge="start"
                                  checked={task.done}
                                  onChange={() =>
                                    handleTaskChange(index, !task.done)
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": index }}
                                />
                                {task.name}
                              </>
                            }
                            secondary={task.description}
                          />
                          <Box
                            sx={{
                              width: "20%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          ></Box>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>
            )}
            {/* {options && (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", ml: 1, mb: 1 }}
                >
                  Actions
                </Typography>
                {options.length > 0 ? (
                  options.map((option, index) => (
                    <Button
                      key={index}
                      onClick={() => {
                        data.sendMessage(option.message, []);
                      }}
                      disabled={false}
                      variant="outlined"
                      size="small"
                      startIcon={option.icon}
                      sx={{
                        m: 0.5,
                        textAlign: "left",
                        textTransform: "none",
                        width: "100%",
                        padding: theme.spacing(0.5, 1),
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography noWrap variant="body2">
                        {option.description}
                      </Typography>
                    </Button>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: theme.palette.text.disabled,
                      width: "100%",
                    }}
                  >
                    No suggestions available
                  </Typography>
                )}
              </Box>
            )} */}
          </Box>
        </Paper>
      </Box>

      <Modal open={open} onClose={handleClose} closeAfterTransition>
        {/* <Fade in={open}> */}
        <Box sx={modalStyle}>
          <Artifact
            artifact={artifactContent}
            sendMessage={data.sendMessage}
            chatbotLoading={data.chatbotLoading}
            embedInModal={false}
          />
        </Box>
        {/* </Fade> */}
      </Modal>
      {/* The main node body */}
    </Box>
  );
}

export default StepDetailsNode;
