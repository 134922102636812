import React, { useState, useEffect } from "react";
import {
  Handle,
  Position,
  NodeToolbar,
  // if you are using @reactflow/node-toolbar, then import from that package
} from "@xyflow/react";
import {
  Box,
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Paper,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/DoneRounded";
import InProgressIcon from "@mui/icons-material/HourglassEmptyRounded";
import NewIcon from "@mui/icons-material/FiberNewRounded";
import CancelIcon from "@mui/icons-material/DeleteForeverOutlined";
import BypassIcon from "@mui/icons-material/AirlineStopsRounded";
import UnknownIcon from "@mui/icons-material/HelpRounded";
import RejectedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { getItemArtifacts, updateAgendaItem } from "../../api/request";
import Artifact from "../Chatbot/Artifacts/Artifact";
import { translateOptions } from "../../utils/formatting";
import { use } from "marked";

function StepNode({ id, data, selected }) {
  const theme = useTheme();

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [artifactContent, setArtifactContent] = useState(null);

  const [fixedOptions, setFixedOptions] = useState([]);

  const [artifacts, setArtifacts] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchArtifacts = async () => {
      if (data.active) {
        // console.log("fetching artifacts -- ", data.id, selected);
        const { artifacts, options } = await getItemArtifacts(
          data.requestId,
          data.id
        );
        console.log("artifacts", artifacts);
        console.log("options", options);
        setArtifacts(artifacts);
        setOptions(translateOptions(options));
      }
    };
    fetchArtifacts();
  }, [data.requestId, data.id, selected]);

  useEffect(() => {
    setFixedOptions(
      translateOptions(
        translateOptions([
          {
            description: "Approve",
            // icon: "CheckCircleIcon",
            color: "success",
            variant: "contained",
            message: `Mark ${data.title} as completed`,
          },
          {
            description: "Reject",
            // icon: "CancelIcon",
            color: "error",
            variant: "contained",
            message: `Mark ${data.title} as rejected`,
          },
        ])
      )
    );
  }, [data.title]);
  // Introduce local state for approvers
  const [approvers, setApprovers] = useState(
    data.approvers ? data.approvers.map((a) => a.email) : []
  );

  // Update local state when data.approvers changes
  useEffect(() => {
    setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
  }, [data.approvers]);

  const getIcon = (icon, fontSize = 24) => {
    switch (icon) {
      case "new":
        return (
          <NewIcon
            sx={{ color: theme.palette.info.main, fontSize: fontSize }}
          />
        );
      case "in_progress":
        return (
          <InProgressIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      case "complete":
        return (
          <DoneIcon
            sx={{ color: theme.palette.success.main, fontSize: fontSize }}
          />
        );
      case "rejected":
        return (
          <RejectedIcon
            sx={{ color: theme.palette.error.main, fontSize: fontSize }}
          />
        );
      case "cancelled":
        return (
          <CancelIcon
            sx={{ color: theme.palette.error.main, fontSize: fontSize }}
          />
        );
      case "not_applicable":
        return (
          <BypassIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      case "unknown":
        return (
          <UnknownIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      default:
        return null;
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newStatus: newStatus,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating status:", error);
      const errorMessage =
        error.response?.data?.error ||
        "An unexpected error occurred while updating the status.";
      setError(errorMessage);
    }
  };

  const handleApproverChange = async (event) => {
    const newApproversEmails = event.target.value.filter((email) =>
      email.includes("@")
    );

    console.log("newApproversEmails", newApproversEmails);

    // Optimistically update local state
    setApprovers(newApproversEmails);

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newAssignedUsersEmails: newApproversEmails,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating approvers:", error);
      const errorMessage =
        error.response?.data?.error ||
        "An unexpected error occurred while updating the approvers.";
      setError(errorMessage);

      // Revert local state if API call fails
      setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
    }
  };

  const handleDateChange = async (event) => {
    let newDate = event.target.value;

    // Extract the year from the newDate
    const year = new Date(newDate).getFullYear();

    // If the year is before 2010, set it to 2010
    if (year < 2010) {
      newDate = new Date(newDate).setFullYear(2010);
      newDate = new Date(newDate).toISOString().split("T")[0]; // Convert back to YYYY-MM-DD format
    }

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newDate: newDate,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating date:", error);
    }
  };

  // Optional: display extra content inside the node if needed
  const renderContent = () => {
    if (!data.content) return null;
    return data.content;
  };

  return (
    <Box className="nopan">
      <Box
        sx={{
          backgroundColor: data.active
            ? theme.palette.background.default
            : theme.palette.background.dark,
          border: data.active
            ? `3px solid ${theme.palette.info.main}`
            : `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          width: data.nodeWidth || "15rem",
          minHeight: data.nodeHeight || "6rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2),
          cursor: "pointer",
        }}
      >
        {/* Input and output handles */}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {getIcon(data.icon)}
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                marginLeft: theme.spacing(1),
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
                maxWidth: "90%",
                minHeight: "4em",
              }}
              title={data.title}
            >
              {data.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>{renderContent()}</Box>
      </Box>
    </Box>
  );
}

export default StepNode;
