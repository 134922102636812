import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { motion } from "framer-motion"; // Import Framer Motion
import Overview from "./Tabs/Overview";
import IntakeForm from "./Tabs/IntakeForm";
import AuditLog from "./Tabs/AuditLog";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Chatbot from "../../components/Chatbot/Chatbot";
import useRequest from "../../hooks/useRequest";
import DocumentExplorer from "./Tabs/DocumentExplorer";
import { useParams, useNavigate } from "react-router-dom";
import useChat from "../../hooks/useChat";

import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";

const pageVariants = {
  initial: {
    opacity: 0,
    x: 10,
    transition: {
      duration: 0.2,
    },
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  out: {
    opacity: 0,
    x: -10,
    transition: {
      duration: 0.2,
    },
  },
};

const RequestPage = ({ user, contacts, allUsers }) => {
  const { requestId } = useParams();
  const navigate = useNavigate();

  const {
    allFiles,
    setAllFiles,
    activeAgent,
    setActiveAgent,
    activeTab,
    setActiveTab,
    relatedConversations,
    sortedRelatedConversations,
    handleNewMessages,
    handleTabChange,
    requestSteps,
    requestForms,
    replySubject,
    setReplySubject,
    replyBody,
    setReplyBody,
    toTags,
    setToTags,
    ccTags,
    setCcTags,
    bccTags,
    setBccTags,
    attachments,
    setAttachments,
    selectedConversation,
    setSelectedConversation,
    currentView,
    setCurrentView,
    resetDrafting,
    request,
    draftingEmailId,
    setDraftingEmailId,
    getRequestDetails,
    activeNode,
    setActiveNode,
    handleNodeClick,
  } = useRequest(requestId);

  const {
    messages,
    setMessages,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    options,
    setOptions,
    resetChat,
    chatbotLoading,
    setChatbotLoading,
    chatbotFiles,
    setChatbotFiles,
    sendMessage,
    selectedFiles,
    setSelectedFiles,
    inputRef,
    canvas,
    setCanvas,
    updateCanvas,
  } = useChat({
    initParams: {
      request_id: requestId,
      active_approval_id: activeNode?.id || null,
    },
    handleNewMessages,
  });

  useEffect(() => {
    if (!request) {
      // Handle case where request is not found or is loading
      // You can redirect or show an error message
    }
  }, [request]);

  if (!request) {
    return <></>;
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        {/* Left Panel */}
        <Box sx={{ width: "40%", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              px: 4,

              height: "7%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",

                width: "100%",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ArrowBack
                  sx={{ cursor: "pointer", color: "text.disabled" }}
                  onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                />
                <Typography variant="h5" sx={{ mx: 2 }} noWrap>
                  {request.title}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "40%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                my: 0,
              }}
            >
              <Tooltip title="Reset Chat">
                <IconButton
                  onClick={() => {
                    resetChat();
                  }}
                  color="error"
                  disabled={chatbotLoading}
                >
                  <RotateLeftRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ height: "93%" }}>
            <Chatbot
              user={user}
              messages={messages}
              setMessages={setMessages}
              activePolicy={activePolicy}
              setActivePolicy={setActivePolicy}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              options={options}
              setOptions={setOptions}
              chatbotLoading={chatbotLoading}
              setChatbotLoading={setChatbotLoading}
              chatbotFiles={chatbotFiles}
              setChatbotFiles={setChatbotFiles}
              sendMessage={sendMessage}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              inputRef={inputRef}
            />
          </Box>
        </Box>

        {/* Right Panel with Tabs */}
        <Box
          sx={{
            width: "60%",
            height: "100%",
            position: "relative",
            borderLeft: "1px solid #e0e0e0",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            backgroundColor: "background.dark",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Request Tabs"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxheight: "5%",
              position: "sticky",
              top: 0,
              zIndex: 999,
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <Tab label="Overview" />
            <Tab label="Intake Form" />
            {/* <Tab label="Emails" /> */}
            <Tab label={`Documents (${request.files.length})`} />
            <Tab label="Audit Log" />
          </Tabs>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              px: 2,
              py: 2,
            }}
          >
            {activeTab === 0 && (
              <Overview
                user={user}
                steps={requestSteps}
                request={request}
                allUsers={allUsers}
                getRequestDetails={getRequestDetails}
                sendMessage={sendMessage}
                chatbotLoading={chatbotLoading}
                canvas={canvas}
                setCanvas={setCanvas}
                updateCanvas={updateCanvas}
                activeNode={activeNode}
                handleNodeClick={handleNodeClick}
              />
            )}

            {activeTab === 1 && <IntakeForm forms={requestForms} />}

            {/* {activeTab === 2 && (
              <Box sx={{ height: "100%" }}>
                <RelatedConversations
                  resetDrafting={resetDrafting}
                  user={user}
                  request={request}
                  sortedRelatedConversations={sortedRelatedConversations}
                  contacts={contacts}
                  replySubject={replySubject}
                  setReplySubject={setReplySubject}
                  replyBody={replyBody}
                  setReplyBody={setReplyBody}
                  toTags={toTags}
                  setToTags={setToTags}
                  ccTags={ccTags}
                  setCcTags={setCcTags}
                  bccTags={bccTags}
                  setBccTags={setBccTags}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  selectedConversation={selectedConversation}
                  setSelectedConversation={setSelectedConversation}
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                  draftingEmailId={draftingEmailId}
                  setDraftingEmailId={setDraftingEmailId}
                />
              </Box>
            )} */}

            {activeTab === 2 && (
              <DocumentExplorer files={request.files} requestId={request.id} />
            )}

            {activeTab === 3 && (
              <AuditLog requestId={request.id} logs={request.audit_log} />
            )}
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default RequestPage;
