import React, { useMemo } from "react";
import {
  List,
  ListItemText,
  Box,
  Typography,
  Grid,
  Chip,
  ListItemButton,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toCamelCase } from "../../utils/formatting";

const DashboardHome = ({
  requests,
  user,
  searchQuery,
  handleSearch,
  statusFilter,
  handleStatusFilter,
  sortOrder,
  changeSortOrder,
  loading, // Ensure loading state is passed
}) => {
  const navigate = useNavigate();

  const formattedRequests = Object.entries(requests).map(([id, request]) => ({
    id,
    ...request,
  }));

  const handleSelectRequest = (request) => {
    navigate(`/dashboard/request/${request.id}`);
  };

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Requests List */}
          <List>
            <ListItem
              sx={{ backgroundColor: "primary.main", mb: 0, mt: 0 }}
              dense
            >
              <ListItemText
                primary={
                  <Typography variant="h6" color="primary.contrastText">
                    Assigned to You
                  </Typography>
                }
              />
            </ListItem>
            {formattedRequests.length > 0 ? (
              formattedRequests.map((request) => (
                <ListItemButton
                  key={request.id}
                  onClick={() => handleSelectRequest(request)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "background.dark",
                      cursor: "pointer",
                    },
                    px: 4,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <Grid item sx={{ width: "40%" }}>
                      <ListItemText
                        primary={
                          <Typography noWrap variant="subtitle1">
                            {request.title}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item sx={{ width: "25%" }}>
                      <ListItemText
                        primary={
                          <Typography noWrap color="text.secondary">
                            {request.requester_name}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item sx={{ width: "20%", textAlign: "center" }}>
                      <Chip
                        label={toCamelCase(request.status)}
                        variant="contained"
                        sx={{
                          width: "100%",
                        }}
                        color={
                          request.status === "in_progress"
                            ? "warning"
                            : request.status === "complete"
                            ? "success"
                            : request.status === "new"
                            ? "info"
                            : request.status === "rejected" ||
                              request.status === "cancelled"
                            ? "error"
                            : "info"
                        }
                      />
                    </Grid>
                    <Grid item sx={{ width: "15%", textAlign: "right" }}>
                      <ListItemText
                        primary={
                          isValid(new Date(request.submitted_at))
                            ? format(
                                new Date(request.submitted_at),
                                "MMM d, yyyy"
                              )
                            : "N/A"
                        }
                      />
                    </Grid>
                  </Grid>
                </ListItemButton>
              ))
            ) : (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  mt: 4,
                  color: "text.disabled",
                }}
              >
                <Typography variant="h5">
                  You do not have any requests assigned to you
                </Typography>
              </Box>
            )}
          </List>
        </>
      )}
    </Box>
  );
};

export default DashboardHome;
