import React, { useEffect } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";

import { Box } from "@mui/material";
import "@xyflow/react/dist/style.css";
import StepNode from "./StepNode";
import { getStepNodeData } from "./Utils";

const nodeWidth = 20 * 16;
const nodeHeight = 10 * 16;

// --- Lay out nodes in columns/rows ---
const getLayoutedElements = (nodes, edges) => {
  const horizontalSpacing = nodeWidth + 50; // Adjust spacing as needed
  const verticalSpacing = nodeHeight + 70;

  nodes.forEach((node) => {
    // node.id => "step-groupIndex-stepIndex"
    const [_, groupIdx, stepIdx] = node.id.split("-");
    const groupNodes = nodes.filter((n) =>
      n.id.startsWith(`step-${groupIdx}-`)
    );
    const groupHeight = (groupNodes.length - 1) * verticalSpacing;

    node.position = {
      x: groupIdx * horizontalSpacing,
      y: stepIdx * verticalSpacing - groupHeight / 2,
    };
    node.targetPosition = "left";
    node.sourcePosition = "right";
  });

  return { nodes, edges };
};

const nodeTypes = { stepNode: StepNode };

function FLD({
  user,
  allUsers,
  steps,
  theme,
  activeNode,
  request,
  sendMessage,
  getRequestDetails,
  handleNodeClick,
}) {
  const { setViewport } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    if (!steps?.length) {
      setNodes([]);
      setEdges([]);
      return;
    }

    // 0) Filter out step items that are not required
    let filteredSteps = steps
      .map((stepGroup) => stepGroup.filter((step) => step.required))
      .filter((stepGroup) => stepGroup.length > 0);

    console.log("filteredSteps", filteredSteps);

    // 1) Generate base nodes & edges from `steps`
    let generatedNodes = [];
    let generatedEdges = [];

    filteredSteps.forEach((stepGroup, groupIndex) => {
      stepGroup.forEach((step, stepIndex) => {
        const nodeId = `step-${groupIndex}-${stepIndex}`;
        generatedNodes.push({
          id: nodeId,
          // Provide the step data for StepNode
          data: {
            ...getStepNodeData(step, nodeWidth, nodeHeight),
            getRequestDetails,
            requestId: request.id,
            allUsers: allUsers,
            sendMessage: sendMessage,
            handleNodeClick: handleNodeClick,
          },
          type: "stepNode",
          draggable: false,
          selected: step.title === activeNode?.title,
        });

        // Connect edges from previous group to this group
        if (groupIndex > 0) {
          const prevGroup = filteredSteps[groupIndex - 1];
          prevGroup.forEach((_, prevIndex) => {
            const sourceId = `step-${groupIndex - 1}-${prevIndex}`;
            const targetId = nodeId;
            generatedEdges.push({
              id: `${sourceId}-${targetId}`,
              source: sourceId,
              target: targetId,
              type: "step",
              animated: true,
              style: {
                stroke: theme.palette.success.main,
                strokeWidth: 2,
              },
            });
          });
        }
      });
    });

    // 2) Layout the diagram
    let { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      generatedNodes,
      generatedEdges
    );

    // Preserve the selected state
    layoutedNodes = layoutedNodes.map((node) => ({
      ...node,
      selected: node.data.title === activeNode?.title,
    }));

    // 3) Update our state
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    // 4) Auto-center on the selected node
    const targetNode = layoutedNodes.find(
      (n) => n.data.title === activeNode?.title
    );

    if (targetNode) {
      const zoom = 0.6;
      const container = document.querySelector(".react-flow");

      if (container) {
        const { width, height } = container.getBoundingClientRect();
        const x =
          -targetNode.position.x * zoom + width / 2 - (nodeWidth * zoom) / 2;
        const y =
          -targetNode.position.y * zoom + height / 8 - (nodeHeight * zoom) / 2;

        setViewport({ x, y, zoom }, { duration: 250 });
      } else {
        // Fallback if container not found
        setViewport(
          {
            x: -targetNode.position.x * zoom,
            y: -targetNode.position.y * zoom,
            zoom,
          },
          { duration: 250 }
        );
      }
    }
  }, [steps, activeNode, setViewport, theme, request, sendMessage, allUsers]);

  // Basic edge connect logic
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        border: `1px solid ${theme.palette.background.dark}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        overflow: "hidden", // Prevents scrollbars from appearing
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        minZoom={0.3}
        maxZoom={0.6}
        // nodeExtent defines the bounding box [topLeft, bottomRight]
        defaultViewport={{ x: 50, y: 200, zoom: 0.6 }}
        attributionPosition="top-right"
        onPaneClick={(event) => {
          handleNodeClick(null);
        }}
        onNodeClick={(event, node) => {
          handleNodeClick(node);
        }}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls showInteractive={false} style={{ flexDirection: "row" }} />
      </ReactFlow>
    </Box>
  );
}

export default function FlowDiagram({
  user,
  allUsers,
  steps,
  request,
  theme,
  handleNodeClick,
  onStatusChange,
  onApproverChange,
  getRequestDetails,
  sendMessage,
  activeNode,
}) {
  return (
    <ReactFlowProvider>
      <FLD
        user={user}
        allUsers={allUsers}
        steps={steps}
        request={request}
        theme={theme}
        handleNodeClick={handleNodeClick}
        onStatusChange={onStatusChange}
        onApproverChange={onApproverChange}
        getRequestDetails={getRequestDetails}
        sendMessage={sendMessage}
        activeNode={activeNode}
      />
    </ReactFlowProvider>
  );
}
