import React, { useState } from "react";
import { Box, Paper, Button, Typography, Link, Divider } from "@mui/material";
import Artifact from "../../../components/Chatbot/Artifacts/Artifact";

const Contract = ({ request, contract }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%", // Make sure the parent has a defined height
      }}
    >
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
      >
        {request.contract_id === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography variant="h4" color="text.disabled" sx={{ mb: 4 }}>
              No contract is created for this request yet
            </Typography>

            <Artifact
              artifact={{
                ...contract,
                title: "Draft Contract",
              }}
              sendMessage={() => {}}
              chatbotLoading={false}
              embedInModal={true}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: "100%",
              py: 2,
            }}
          >
            <Typography
              variant="h4"
              color="text.disabled"
              sx={{ mb: 1, mx: 4 }}
            >
              Contract Id:{" "}
              <Link href={contract.contract_link} target="_blank">
                {contract.contract_id}
              </Link>
            </Typography>
            <Divider sx={{ m: 0 }} />
            <Artifact
              artifact={contract}
              sendMessage={() => {}}
              chatbotLoading={false}
              embedInModal={false}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Contract;
