import { Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export const getStepNodeData = (
  step,
  nodeWidth = "20rem",
  nodeHeight = "6rem"
) => {
  return {
    id: step.id,
    title: step.name,
    icon: step.status,

    status: step.status,
    approvers: step.approvers,
    date: step.date,
    tasks: step.tasks,
    content: (
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <PersonIcon />
          <Typography variant="body2">
            {step?.approvers?.length > 1
              ? `${step.approvers[0].name} (+${step.approvers.length - 1})`
              : step?.approvers?.[0]?.name || "None"}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <CalendarTodayIcon />
          <Typography variant="body2">
            {new Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
              timeZone: "UTC",
            }).format(new Date(step.date))}
          </Typography>
        </Box>
      </Box>
    ),
    nodeWidth,
    nodeHeight,
  };
};
