import React, { useEffect, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";

import { Box } from "@mui/material";
import "@xyflow/react/dist/style.css";
import StepNode from "./StepNode";
import StepDetailsNode from "./StepDetailsNode";
import { getStepNodeData } from "./Utils";

const nodeWidth = 15 * 16;
const nodeHeight = 6 * 16;

// --- Lay out nodes in columns/rows ---
const getLayoutedElements = (nodes, edges) => {
  const horizontalSpacing = nodeWidth + 50; // Adjust spacing as needed
  const verticalSpacing = nodeHeight + 70;

  nodes.forEach((node) => {
    // node.id => "step-groupIndex-stepIndex"
    const [_, groupIdx, stepIdx] = node.id.split("-");
    const groupNodes = nodes.filter((n) =>
      n.id.startsWith(`step-${groupIdx}-`)
    );
    const groupHeight = (groupNodes.length - 1) * verticalSpacing;

    node.position = {
      x: groupIdx * horizontalSpacing,
      y: stepIdx * verticalSpacing - groupHeight / 2,
    };
    node.targetPosition = "left";
    node.sourcePosition = "right";
  });

  return { nodes, edges };
};

const nodeTypes = {
  stepNode: StepNode,
  stepDetailsNode: StepDetailsNode,
};

function FLD({
  user,
  allUsers,
  steps,
  theme,
  activeNode,
  request,
  sendMessage,
  getRequestDetails,
  handleNodeClick,
}) {
  // 1) Retrieve details-node location from localStorage (keyed by request.id).
  //    Fallback to { x: 400, y: 500 } if none found.
  const initialDetailsNodeLocation = React.useMemo(() => {
    const storedLocation = localStorage.getItem(
      `flow-${request?.id}-detailsLocation`
    );
    return storedLocation ? JSON.parse(storedLocation) : { x: 400, y: 500 };
  }, [request?.id]);

  const [lastDetailsNodeLocation, setLastDetailsNodeLocation] = React.useState(
    initialDetailsNodeLocation
  );

  // 2) Keep track of the viewport in localStorage (keyed by request.id).
  const defaultViewport = {
    x: 20,
    y: 200,
    zoom: 0.7,
  };

  const { setViewport } = useReactFlow();

  // 3) On mount or request.id change, load any saved viewport from localStorage.
  React.useEffect(() => {
    const storedViewport = localStorage.getItem(`flow-${request?.id}-viewport`);
    if (storedViewport) {
      setViewport(JSON.parse(storedViewport));
    } else {
      setViewport(defaultViewport);
    }
  }, [request?.id, setViewport]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  // 4) Whenever the lastDetailsNodeLocation changes, persist it
  //    so the user returns to the same details node position on refresh.
  React.useEffect(() => {
    localStorage.setItem(
      `flow-${request?.id}-detailsLocation`,
      JSON.stringify(lastDetailsNodeLocation)
    );
  }, [lastDetailsNodeLocation, request?.id]);

  // 5) The main effect that builds out nodes/edges.
  React.useEffect(() => {
    console.log("steps", steps);
    if (!steps?.length) {
      setNodes([]);
      setEdges([]);
      return;
    }

    // 0) Filter out step items that are not required
    let filteredSteps = steps
      .map((stepGroup) => stepGroup.filter((step) => step.required))
      .filter((stepGroup) => stepGroup.length > 0);

    console.log("filteredSteps", filteredSteps);

    // 1) Generate base nodes & edges from `steps`
    let generatedNodes = [];
    let generatedEdges = [];

    filteredSteps.forEach((stepGroup, groupIndex) => {
      stepGroup.forEach((step, stepIndex) => {
        const nodeId = `step-${groupIndex}-${stepIndex}`;
        generatedNodes.push({
          id: nodeId,
          // Provide the step data for StepNode
          data: {
            ...getStepNodeData(step, nodeWidth, nodeHeight),
            getRequestDetails,
            requestId: request.id,
            allUsers: allUsers,
            sendMessage: sendMessage,
            active: step.title === activeNode?.title,
          },
          type: "stepNode",
          draggable: false,
          // selected: step.title === activeNode?.title,
        });

        // Connect edges from previous group to this group
        if (groupIndex > 0) {
          const prevGroup = filteredSteps[groupIndex - 1];
          prevGroup.forEach((_, prevIndex) => {
            const sourceId = `step-${groupIndex - 1}-${prevIndex}`;
            const targetId = nodeId;
            generatedEdges.push({
              id: `${sourceId}-${targetId}`,
              source: sourceId,
              target: targetId,
              type: "step",
              animated: true,
              style: {
                // stroke: theme.palette.primary.main,
                strokeWidth: 2,
              },
            });
          });
        }
      });
    });

    // 2) Layout the diagram
    let { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      generatedNodes,
      generatedEdges
    );

    // // Preserve the selected state
    // layoutedNodes = layoutedNodes.map((node) => ({
    //   ...node,
    //   selected: node.data.title === activeNode?.title,
    // }));

    layoutedNodes = layoutedNodes.map((node) => ({
      ...node,
      data: { ...node.data, active: node.data.title === activeNode?.title },
    }));

    // 3) For each selected step node, add a details node
    const detailNodes = [];
    layoutedNodes.forEach((node) => {
      if (node.type === "stepNode" && node.data.active) {
        console.log("node", node);
        const detailNodeId = `details-${node.id}`;

        // Make sure we don't accidentally add the same node twice
        if (!layoutedNodes.find((n) => n.id === detailNodeId)) {
          detailNodes.push({
            id: detailNodeId,
            type: "stepDetailsNode",
            position: {
              x: lastDetailsNodeLocation.x,
              y: lastDetailsNodeLocation.y,
            },
            data: {
              ...node.data,
              requestId: request.id,
              handleNodeClick: handleNodeClick,
            },
            draggable: true,
            selectable: false,
          });
        }
      }
    });

    // Optionally, remove any stale detail nodes (i.e. for nodes that were unselected)
    // This is if you want to re-run the effect each time and clear out old details nodes:
    const cleanedNodes = layoutedNodes.filter(
      (n) => n.type !== "stepDetailsNode"
    );

    // Combine with new detail nodes
    const finalNodes = [...cleanedNodes, ...detailNodes];

    setNodes(finalNodes);
    setEdges(layoutedEdges);

    // 4) Auto-center on the selected node
    const targetNode = layoutedNodes.find(
      (n) => n.data.title === activeNode?.title
    );

    // if (targetNode) {
    //   const zoom = 1;
    //   const container = document.querySelector(".react-flow");

    //   if (container) {
    //     const { width, height } = container.getBoundingClientRect();
    //     const x =
    //       -targetNode.position.x * zoom + width / 2 - (nodeWidth * zoom) / 2;
    //     const y =
    //       -targetNode.position.y * zoom + height / 8 - (nodeHeight * zoom) / 2;

    //     setViewport({ x, y, zoom }, { duration: 250 });
    //   } else {
    //     // Fallback if container not found
    //     setViewport(
    //       {
    //         x: -targetNode.position.x * zoom,
    //         y: -targetNode.position.y * zoom,
    //         zoom,
    //       },
    //       { duration: 250 }
    //     );
    //   }
    // }
  }, [steps, activeNode, setViewport, theme, request, sendMessage, allUsers]);

  // Basic edge connect logic
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  const onNodeDragStop = (event, node) => {
    if (node.type === "stepDetailsNode") {
      setLastDetailsNodeLocation(node.position);
    }
  };

  // 7) Listen for changes to the viewport; persist them on move end.
  const onMoveEnd = React.useCallback(
    (event, viewport) => {
      localStorage.setItem(
        `flow-${request?.id}-viewport`,
        JSON.stringify(viewport)
      );
    },
    [request?.id]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        border: `1px solid ${theme.palette.background.dark}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        overflow: "hidden", // Prevents scrollbars from appearing
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        minZoom={0.5}
        maxZoom={1}
        // nodeExtent defines the bounding box [topLeft, bottomRight]
        defaultViewport={{ x: 20, y: 200, zoom: 0.7 }}
        selectionOnDrag={true}
        // panOnScroll={true}
        attributionPosition="top-right"
        onPaneClick={(event) => {
          // handleNodeClick(null);
        }}
        onNodeClick={(event, node) => {
          if (node.type === "stepNode") {
            console.log("NODE CLICKED ", node);
            handleNodeClick(node);
          }
        }}
        onNodeDragStop={onNodeDragStop}
        onMoveEnd={onMoveEnd}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls style={{ flexDirection: "row" }} showZoom={true} />
      </ReactFlow>
    </Box>
  );
}

export default function FlowDiagram({
  user,
  allUsers,
  steps,
  request,
  theme,
  handleNodeClick,
  onStatusChange,
  onApproverChange,
  getRequestDetails,
  sendMessage,
  activeNode,
}) {
  return (
    <ReactFlowProvider>
      <FLD
        user={user}
        allUsers={allUsers}
        steps={steps}
        request={request}
        theme={theme}
        handleNodeClick={handleNodeClick}
        onStatusChange={onStatusChange}
        onApproverChange={onApproverChange}
        getRequestDetails={getRequestDetails}
        sendMessage={sendMessage}
        activeNode={activeNode}
      />
    </ReactFlowProvider>
  );
}
