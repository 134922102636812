import { useState, useEffect } from "react";
import { fetchRequests, fetchAssignedRequests } from "../api/request";
import { fetchUser, fetchAllUsers, pauseAccountAPI } from "../api/auth";
import { useNavigate } from "react-router-dom";

const dummyContacts = {
  atn: {
    "john.doe@example.com": "John Doe",
    "jane.smith@example.com": "Jane Smith",
    "alice.jones@example.com": "Alice Jones",
    "teshnizi@stanford.edu": "Ali Teshnizi",
  },
  nta: {
    "John Doe": "john.doe@example.com",
    "Jane Smith": "jane.smith@example.com",
    "Alice Jones": "alice.jones@example.com",
    "Ali Teshnizi": "teshnizi@stanford.edu",
  },
};

const useDashboard = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [assignedRequests, setAssignedRequests] = useState([]);

  const rowHeight = 50;
  const dynamicPerPage = Math.max(
    1,
    Math.floor((window.innerHeight * 0.65) / rowHeight)
  );

  const [perPage, setPerPage] = useState(dynamicPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");

  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getRequests = async () => {
      setLoading(true);
      try {
        const data = await fetchRequests(
          currentPage,
          perPage,
          sortOrder,
          searchQuery,
          statusFilter
        );

        setRequests(data.requests);
        setTotalPages(data.pagination.pages);
        console.log("Requests: ", data.requests);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getRequests();
  }, [currentPage, perPage, sortOrder, searchQuery, statusFilter, navigate]);

  useEffect(() => {
    const getAssignedRequests = async () => {
      const data = await fetchAssignedRequests();
      setAssignedRequests(data);
    };
    getAssignedRequests();
  }, [navigate]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetchUser();
        setUser(response);
      } catch (err) {
        setError(err);
      }
    };
    getUser();
  }, [navigate]);

  useEffect(() => {
    const getAssignees = async () => {
      const response = await fetchAllUsers();
      setAllUsers(response);
    };
    getAssignees();
  }, [navigate]);

  const pauseAccount = async (pause) => {
    const res = await pauseAccountAPI(pause);
    setUser(res.user);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const changeSortOrder = (newSortOrder) => {
    setSortOrder(newSortOrder);
    setCurrentPage(1);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    setCurrentPage(1);
  };

  return {
    user,
    requests,
    assignedRequests,
    loading,
    error,
    dummyContacts,
    pauseAccount,
    currentPage,
    totalPages,
    goToNextPage,
    goToPrevPage,
    sortOrder,
    changeSortOrder,
    searchQuery,
    handleSearch,
    statusFilter,
    handleStatusFilter,
    allUsers,
    perPage,
    // setPerPage,
    setCurrentPage,
  };
};

export default useDashboard;
