import { useState, useEffect, useRef } from "react";
import { getChat } from "../api/chat";

import { useNavigate } from "react-router-dom";
import { sendMessageToChatbot, getCanvas } from "../api/chat";
import { translateOptions } from "../utils/formatting";

const useChat = ({ initParams, handleNewMessages }) => {
  const [messages, setMessages] = useState([]);
  const [activePolicy, setActivePolicy] = useState(null);
  const [conversationOver, setConversationOver] = useState(false);
  const [options, setOptions] = useState([]);
  const [chatbotLoading, setChatbotLoading] = useState(false);
  const [chatbotFiles, setChatbotFiles] = useState([]);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const inputRef = useRef(null); // Reference to the input field
  const [canvas, setCanvas] = useState({});

  const initializeAgent = async (resetChat = null) => {
    try {
      const { policy, messages } = await getChat(initParams, resetChat);

      console.log("Initilizing agent: ", initParams);
      console.log("POLICY: ", policy);
      console.log("MESSAGES: ", messages);
      setActivePolicy(policy);
      setMessages(messages);

      console.log("Initial Policy: ", policy);
      console.log("Initial Messages: ", messages);
    } catch (error) {
      console.error("Error fetching initial policy:", error);
    }
  };

  useEffect(() => {
    initializeAgent();
  }, []);

  const updateCanvas = async (requestId, stepId) => {
    const { options, canvas } = await getCanvas(requestId, stepId);
    setOptions(translateOptions(options));

    setCanvas(canvas);
    return canvas;
  };

  const sendMessage = async (message, files) => {
    setChatbotLoading(true);

    let newMessages = [
      {
        role: "user",
        content: message,
        files: files.map((file) => ({ name: file.name, type: file.type })),
      },
    ];
    console.log("messages: ", messages);
    let allNewMessages = [...messages, ...newMessages];

    setMessages(allNewMessages);

    const formData = new FormData();
    formData.append("newMessages", JSON.stringify(newMessages));

    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
    }

    formData.append("activePolicy", activePolicy);

    formData.append(
      "requestInformation",
      JSON.stringify({
        request_id: initParams.request_id,
        active_approval_id: initParams.active_approval_id,
      })
    );

    let isConversationOver = conversationOver; // Initialize local variable

    try {
      let continueSending = true;
      let currentActivePolicy = activePolicy;

      while (continueSending) {
        continueSending = false;

        const { newMessages, isDone, newActivePolicy } =
          await sendMessageToChatbot(formData);

        console.log("NEW ACTIVE AGENT: ", newActivePolicy);
        console.log("isDone: ", isDone);

        if (isDone) {
          setConversationOver(true);
          isConversationOver = true; // Update local variable
        }

        if (newActivePolicy && newActivePolicy !== currentActivePolicy) {
          setActivePolicy(newActivePolicy);
          currentActivePolicy = newActivePolicy;
          formData.set("activePolicy", newActivePolicy);
          console.log("Current active agent: ", currentActivePolicy);
          continueSending = true;
        }

        allNewMessages = [...allNewMessages, ...newMessages];
        setMessages(allNewMessages);
        console.log("ALL NEW MESSAGES: ", allNewMessages);

        formData.set("newMessages", JSON.stringify([]));

        const lastMessage = newMessages[newMessages.length - 1];

        if (lastMessage && lastMessage.role == "tool") {
          continueSending = true;
        }
      }
      setChatbotLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }

      handleNewMessages({ newMessages: allNewMessages });
    } catch (error) {
      console.error("Error sending message:", error);
      setChatbotLoading(false);
      setSelectedFiles([]);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const resetChat = async () => {
    console.log("resetChat");
    await initializeAgent(true);
    window.location.reload();
    console.log("messages: ", messages);
  };

  return {
    messages,
    setMessages,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    options,
    setOptions,
    chatbotLoading,
    setChatbotLoading,
    chatbotFiles,
    setChatbotFiles,
    sendMessage,
    selectedFiles,
    setSelectedFiles,
    inputRef,
    updateCanvas,
    canvas,
    resetChat,
  };
};

export default useChat;
