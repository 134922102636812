import React, { useState, useEffect } from "react";
import {
  Handle,
  Position,
  NodeToolbar,
  // if you are using @reactflow/node-toolbar, then import from that package
} from "@xyflow/react";
import {
  Box,
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Paper,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/DoneRounded";
import InProgressIcon from "@mui/icons-material/HourglassEmptyRounded";
import NewIcon from "@mui/icons-material/FiberNewRounded";
import CancelIcon from "@mui/icons-material/DeleteForeverOutlined";
import BypassIcon from "@mui/icons-material/AirlineStopsRounded";
import UnknownIcon from "@mui/icons-material/HelpRounded";
import RejectedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { getItemArtifacts, updateAgendaItem } from "../../api/request";
import Artifact from "../Chatbot/Artifacts/Artifact";
import { translateOptions } from "../../utils/formatting";
import { use } from "marked";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

function StepNode({ id, data, selected }) {
  const theme = useTheme();

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [artifactContent, setArtifactContent] = useState(null);

  const [fixedOptions, setFixedOptions] = useState([]);

  const [artifacts, setArtifacts] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchArtifacts = async () => {
      if (selected) {
        console.log("fetching artifacts -- ", data.id, selected);
        const { artifacts, options } = await getItemArtifacts(
          data.requestId,
          data.id
        );
        console.log("artifacts", artifacts);
        console.log("options", options);
        setArtifacts(artifacts);
        setOptions(translateOptions(options));
      }
    };
    fetchArtifacts();
  }, [data.requestId, data.id, selected]);

  useEffect(() => {
    setFixedOptions(
      translateOptions(
        translateOptions([
          {
            description: "Approve",
            // icon: "CheckCircleIcon",
            color: "success",
            variant: "contained",
            message: `Mark ${data.title} as completed`,
          },
          {
            description: "Reject",
            // icon: "CancelIcon",
            color: "error",
            variant: "contained",
            message: `Mark ${data.title} as rejected`,
          },
        ])
      )
    );
  }, [data.title]);
  // Introduce local state for approvers
  const [approvers, setApprovers] = useState(
    data.approvers ? data.approvers.map((a) => a.email) : []
  );

  // Update local state when data.approvers changes
  useEffect(() => {
    setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
  }, [data.approvers]);

  const getIcon = (icon, fontSize = 36) => {
    switch (icon) {
      case "new":
        return (
          <NewIcon
            sx={{ color: theme.palette.info.main, fontSize: fontSize }}
          />
        );
      case "in_progress":
        return (
          <InProgressIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      case "complete":
        return (
          <DoneIcon
            sx={{ color: theme.palette.success.main, fontSize: fontSize }}
          />
        );
      case "rejected":
        return (
          <RejectedIcon
            sx={{ color: theme.palette.error.main, fontSize: fontSize }}
          />
        );
      case "cancelled":
        return (
          <CancelIcon
            sx={{ color: theme.palette.error.main, fontSize: fontSize }}
          />
        );
      case "not_applicable":
        return (
          <BypassIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      case "unknown":
        return (
          <UnknownIcon
            sx={{ color: theme.palette.warning.main, fontSize: fontSize }}
          />
        );
      default:
        return null;
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newStatus: newStatus,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating status:", error);
      const errorMessage =
        error.response?.data?.error ||
        "An unexpected error occurred while updating the status.";
      setError(errorMessage);
    }
  };

  const handleApproverChange = async (event) => {
    const newApproversEmails = event.target.value.filter((email) =>
      email.includes("@")
    );

    console.log("newApproversEmails", newApproversEmails);

    // Optimistically update local state
    setApprovers(newApproversEmails);

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newAssignedUsersEmails: newApproversEmails,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating approvers:", error);
      const errorMessage =
        error.response?.data?.error ||
        "An unexpected error occurred while updating the approvers.";
      setError(errorMessage);

      // Revert local state if API call fails
      setApprovers(data.approvers ? data.approvers.map((a) => a.email) : []);
    }
  };

  const handleDateChange = async (event) => {
    let newDate = event.target.value;

    // Extract the year from the newDate
    const year = new Date(newDate).getFullYear();

    // If the year is before 2010, set it to 2010
    if (year < 2010) {
      newDate = new Date(newDate).setFullYear(2010);
      newDate = new Date(newDate).toISOString().split("T")[0]; // Convert back to YYYY-MM-DD format
    }

    try {
      await updateAgendaItem({
        requestId: data.requestId,
        agendaItemId: data.id,
        newDate: newDate,
      });
      data.getRequestDetails();
    } catch (error) {
      console.error("Error updating date:", error);
    }
  };

  // Optional: display extra content inside the node if needed
  const renderContent = () => {
    if (!data.content) return null;
    return data.content;
  };

  return (
    <Box className="nopan">
      {/* 
       The NodeToolbar is only visible when 'selected' is true.
       Here you can place your extended controls like assigned-to (approvers), status, and tasks. 
      */}
      <NodeToolbar
        className="nopan"
        isVisible={selected}
        position={Position.Bottom}
        style={{
          width: "80%",
        }}
      >
        <Paper
          elevation={10}
          sx={{
            backgroundColor: "background.default",
            borderRadius: 1,
            padding: theme.spacing(2),
            boxShadow: theme.shadows[3],
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              mb: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {data.title ?? "Untitled Step"}
            <Box>
              {Object.keys(artifacts).map((key) => (
                <Button
                  key={key}
                  variant="contained"
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => {
                    setArtifactContent(artifacts[key]);
                    handleOpen();
                  }}
                >
                  {key}
                </Button>
              ))}
            </Box>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 0 }}>
            <FormControl
              variant="standard"
              sx={{
                backgroundColor: "background.dark",
                borderRadius: "4px",
                height: "2rem",
              }}
              fullWidth
              size="small"
            >
              <Select
                size="small"
                value={data.status || ""}
                onChange={handleStatusChange}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      pl: 1,
                    }}
                  >
                    {getIcon(selected, 20)}
                    <Typography variant="small">
                      {selected.charAt(0).toUpperCase() +
                        selected.slice(1).replace("_", " ")}
                    </Typography>
                  </Box>
                )}
                sx={{ height: "100%" }}
              >
                <MenuItem
                  value="new"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {getIcon("new", 20)}
                  New
                </MenuItem>
                <MenuItem
                  value="in_progress"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {getIcon("in_progress", 20)}
                  In Progress
                </MenuItem>
                <MenuItem
                  value="complete"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {getIcon("complete", 20)}
                  Complete
                </MenuItem>
                <MenuItem
                  value="rejected"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {getIcon("rejected", 20)}
                  Rejected
                </MenuItem>
                <MenuItem
                  value="cancelled"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {getIcon("cancelled", 20)}
                  Cancelled
                </MenuItem>
                <MenuItem
                  value="not_applicable"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {getIcon("not_applicable", 20)}
                  Not Applicable
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl
              sx={{
                backgroundColor: "background.dark",
                borderRadius: "4px",
                height: "2rem",
              }}
              fullWidth
              size="small"
            >
              <TextField
                label=""
                type="date"
                value={new Date(data.date).toISOString().split("T")[0]}
                onChange={handleDateChange}
                variant="standard"
                slotProps={{
                  input: {
                    sx: {
                      pl: 1,
                      height: "100%",
                    },
                  },
                }}
                size="small"
                sx={{ height: "100%" }}
              />
            </FormControl>
          </Box>
          {data.allUsers && (
            <FormControl
              sx={{
                backgroundColor: "background.dark",
                borderRadius: "4px",
                height: "2rem",
              }}
              fullWidth
              size="small"
            >
              <Select
                variant="standard"
                size="small"
                multiple
                value={approvers}
                sx={{ height: "100%", pl: 1 }}
                displayEmpty
                onChange={handleApproverChange}
                renderValue={(selected) => {
                  if (selected.length === 0) return "Not assigned to Anyone";

                  const names =
                    selected
                      .map(
                        (userEmail) =>
                          data.allUsers.find((u) => u.email === userEmail)
                            ?.name || userEmail
                      )
                      .join(", ") || "";

                  return names;
                }}
              >
                {data.allUsers.map((user) => (
                  <MenuItem key={user.email} value={user.email} dense>
                    <Checkbox
                      checked={approvers.includes(user.email)}
                      sx={{
                        pt: 0.5,
                        pb: 0.5,
                        pl: 0.5,
                      }}
                    />
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box sx={{ display: "flex", gap: 2 }}>
            {data.tasks && (
              <Paper
                elevation={0}
                onWheelCapture={(evt) => {
                  evt.stopPropagation();
                  evt.nativeEvent.stopImmediatePropagation();
                }}
                sx={{
                  flex: 1,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold", ml: 1 }}>
                  Tasks
                </Typography>
                <List
                  dense
                  sx={{
                    maxHeight: "40vh",
                    overflowY: "auto",
                    pt: 0,
                  }}
                >
                  {data.tasks.map((task, index) => (
                    <ListItem key={index} sx={{ margin: 0 }}>
                      <ListItemText
                        primary={`${index + 1}. ${task.name}`}
                        // secondary={task.description}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
            {options && (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", ml: 1, mb: 1 }}
                >
                  Actions
                </Typography>
                {options.length > 0 ? (
                  options.map((option, index) => (
                    <Button
                      key={index}
                      onClick={() => {
                        data.sendMessage(option.message, []);
                      }}
                      disabled={false}
                      variant="outlined"
                      size="small"
                      startIcon={option.icon}
                      sx={{
                        m: 0.5,
                        textAlign: "left",
                        textTransform: "none",
                        width: "100%",
                        padding: theme.spacing(0.5, 1),
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography noWrap variant="body2">
                        {option.description}
                      </Typography>
                    </Button>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: theme.palette.text.disabled,
                      width: "100%",
                    }}
                  >
                    No suggestions available
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {fixedOptions.map((option, index) => (
              <Button
                key={index}
                onClick={() => {
                  data.sendMessage(option.message, []);
                }}
                disabled={false}
                variant="contained"
                color={option?.color || "primary"}
                size="small"
                startIcon={option.icon}
                sx={{
                  m: 0.5,
                  textAlign: "left",
                  textTransform: "none",
                  width: "45%",
                  padding: theme.spacing(0.5, 1),
                  justifyContent: "center",
                }}
              >
                <Typography noWrap variant="body2">
                  {option.description}
                </Typography>
              </Button>
            ))}
          </Box>
        </Paper>
      </NodeToolbar>

      <Modal open={open} onClose={handleClose} closeAfterTransition>
        {/* <Fade in={open}> */}
        <Box sx={modalStyle}>
          <Artifact
            artifact={artifactContent}
            sendMessage={data.sendMessage}
            chatbotLoading={data.chatbotLoading}
            embedInModal={false}
          />
        </Box>
        {/* </Fade> */}
      </Modal>
      {/* The main node body */}
      <Box
        sx={{
          backgroundColor: selected
            ? theme.palette.background.default
            : theme.palette.background.dark,
          border: selected
            ? `3px solid ${theme.palette.info.main}`
            : `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          width: data.nodeWidth || "15rem",
          minHeight: data.nodeHeight || "6rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2),
          cursor: "pointer",
        }}
      >
        {/* Input and output handles */}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {getIcon(data.icon)}
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                marginLeft: theme.spacing(1),
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
                maxWidth: "90%",
                minHeight: "4em",
              }}
              title={data.title}
            >
              {data.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>{renderContent()}</Box>
      </Box>
    </Box>
  );
}

export default StepNode;
