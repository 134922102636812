const isConditionMet = (formConfig, condition) => {
  const { field_id, values, min_value, max_value } = condition;
  // const sectionData = formConfig.find((s) => s.title === section);
  // if (!sectionData) return false;
  const fieldData = formConfig
    .flatMap((section) => section.fields)
    .find((f) => f.id === field_id);

  let fieldValue = fieldData?.value;
  if (fieldValue && typeof fieldValue === "object") {
    fieldValue = fieldValue.value;
  }
  if (values) {
    return values.includes(fieldValue);
  }
  if (min_value && fieldValue <= min_value) {
    return false;
  }
  if (max_value && fieldValue >= max_value) {
    return false;
  }
  return true;
};

export const getVisibleSections = (formConfig) => {
  if (!formConfig || formConfig.length === 0) return [];

  console.log("formConfig", formConfig);
  return formConfig.filter((section) => {
    if (section.conditions && section.conditions.length > 0) {
      let or_condition_met = false;
      for (let or_condition of section.conditions) {
        let and_condition_met = true;
        for (let and_condition of or_condition) {
          if (!isConditionMet(formConfig, and_condition)) {
            and_condition_met = false;
            break;
          }
        }
        if (and_condition_met) {
          or_condition_met = true;
          break;
        }
      }
      if (or_condition_met) {
        return true;
      }
    }
    return false;
  });
};

export const getErrors = (formConfig) => {
  const newErrors = {};
  const newErrorFields = [];
  const visibleSections = getVisibleSections(formConfig);

  console.log("visibleSections: ", visibleSections);
  visibleSections.forEach((section, sectionIndex) => {
    section.fields.forEach((field, fieldIndex) => {
      if (field.type === "table") {
        // Check each cell in the table
        field.value.forEach((row, rowIndex) => {
          row.forEach((cell, colIndex) => {
            if (!cell) {
              if (!newErrors[sectionIndex]) newErrors[sectionIndex] = {};
              if (!newErrors[sectionIndex][fieldIndex])
                newErrors[sectionIndex][fieldIndex] = {};
              if (!newErrors[sectionIndex][fieldIndex][rowIndex])
                newErrors[sectionIndex][fieldIndex][rowIndex] = {};
              newErrors[sectionIndex][fieldIndex][rowIndex][colIndex] = true;
              newErrorFields.push(
                `${field.name} Row ${rowIndex + 1} Column ${colIndex + 1}`
              );
            }
          });
        });
      } else if (
        !field.name.toLowerCase().includes("optional") &&
        !field.value
      ) {
        if (!newErrors[sectionIndex]) newErrors[sectionIndex] = {};
        newErrors[sectionIndex][fieldIndex] = true;
        newErrorFields.push(field.name);
      }
    });
  });

  return { newErrors, newErrorFields };
};

export const formatCurrency = (value) => {
  if (value === undefined || value === null || value === "") return "";
  const number = Number(value);
  if (isNaN(number)) return value;
  return `$${number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })}`;
};

export const deformatCurrency = (value) => {
  if (value === undefined || value === null || value === "") return "";
  return parseFloat(value.replace("$", "").replace(/,/g, ""));
};
