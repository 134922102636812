import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,
  Avatar,
  Divider,
  Switch,
  Button,
  Modal,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import RequestsListPage from "./Requests/RequestsListPage";
import DashboardHome from "./Home/Home";
import RequestPage from "./Requests/RequestPage";
import Settings from "./Settings/Settings"; // Import the Settings component
import { logout } from "../api/auth";
import useDashboard from "../hooks/useDashboard";
import DrawerComponent from "./DrawerComponent";
import routeConfig from "./routeConfig"; // Import route configurations

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const {
    user,
    requests,
    loading,
    error,
    dummyContacts,
    pauseAccount,
    currentPage,
    totalPages,
    goToNextPage,
    goToPrevPage,
    sortOrder,
    changeSortOrder,
    searchQuery,
    handleSearch,
    statusFilter,
    handleStatusFilter,
    allUsers,
    assignedRequests,
    setCurrentPage,
    // setPerPage,
  } = useDashboard();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      console.log("Logged out successfully");
      window.location.href = "/";
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handlePauseToggle = async (event) => {
    console.log("Account paused:", event.target.checked);
    await pauseAccount(event.target.checked);
  };

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  // Determine if the current route should display the Drawer based on routeConfig
  const showDrawer = Object.keys(routeConfig).some((route) =>
    location.pathname.startsWith(route)
  );

  // Get the current route's title from routeConfig
  const headerTitle =
    routeConfig[
      Object.keys(routeConfig).find((route) =>
        location.pathname.startsWith(route)
      )
    ] || "Dashboard";

  if (error) {
    return (
      <Typography color="text">
        Error loading requests: {error.message}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {showDrawer && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              px: 4,
              py: 2,
              width: "100%",
              height: "5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h5"
                sx={{
                  mx: 2,
                  color: "primary.main",
                }}
              >
                {headerTitle}
              </Typography>
            </Box>
            <Box>
              {user?.organization_id && (
                <img
                  src={`/clients/${user?.organization_id}.png`}
                  alt={`${user?.organization_id}.png`}
                  onError={(e) => {
                    console.log(
                      "Error loading vendor image: ",
                      `/clients/${user?.organization_id}.png`
                    );
                    // e.target.onerror = null;
                    // e.target.src = "/clients/Default.svg";
                  }}
                  style={{ width: 96, height: "auto" }}
                />
              )}
            </Box>
          </Box>

          <DrawerComponent
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            user={user}
            handlePauseToggle={handlePauseToggle}
            handleLogout={handleLogout}
          />
        </>
      )}

      <Box sx={{ flexGrow: 1, width: "100%", overflow: "auto" }}>
        <Routes>
          <Route path="/" element={<Navigate to="home" />} />
          <Route
            path="home"
            element={
              <DashboardHome
                loading={loading}
                requests={assignedRequests}
                user={user}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                statusFilter={statusFilter}
                handleStatusFilter={handleStatusFilter}
                sortOrder={sortOrder}
                changeSortOrder={changeSortOrder}
              />
            }
          />
          <Route
            path="all_requests"
            element={
              <RequestsListPage
                loading={loading}
                requests={requests}
                user={user}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                statusFilter={statusFilter}
                handleStatusFilter={handleStatusFilter}
                sortOrder={sortOrder}
                changeSortOrder={changeSortOrder}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                // setPerPage={setPerPage}
              />
            }
          />
          {/* Uncomment if you have an InboxPage */}
          {/* <Route path="inbox" element={<InboxPage />} /> */}
          <Route
            path="request/:requestId"
            element={
              <RequestPage
                user={user}
                contacts={dummyContacts}
                allUsers={allUsers}
              />
            }
          />
          <Route path="settings" element={<Settings user={user} />} />{" "}
          {/* New Settings Route */}
          {/* Fallback Route */}
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
