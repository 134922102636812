import { useState } from "react";
import FormDisplay from "../../FormDisplay";
import {
  Box,
  Button,
  Modal,
  Typography,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { sendFormRequest } from "../../../api/request";

const FormArtifact = ({ artifact, sendMessage, chatbotLoading }) => {
  const [formConfig, setFormConfig] = useState(artifact.sections);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogColor, setDialogColor] = useState(null);
  const [loading, setLoading] = useState(false);

  // New state for confirmation dialog
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [proceedWithCreation, setProceedWithCreation] = useState(false);

  function setIsModified(isModified) {
    console.log(isModified);
  }

  function handleFormUpdate(formData) {
    console.log(formData);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  // Function to handle the actual contract creation
  const handleSendFormRequest = async (action) => {
    setLoading(true);
    try {
      setDialogTitle("Processing...");
      setDialogMessage(null);
      setDialogColor("info");
      const response = await sendFormRequest(
        action.endpoint_url,
        artifact,
        formConfig
      );
      console.log("RESPONSE: ", response);
      setDialogTitle("Success!");
      setDialogMessage({
        __html: response.message,
      });
      setDialogColor("success");
    } catch (error) {
      console.error("Error sending request:", error);
      setDialogTitle("Error sending request");
      let errs = error.response?.data?.error || "An unexpected error occurred.";
      setDialogMessage({ __html: errs });
      setDialogColor("error");
    } finally {
      setLoading(false);
      setOpen(true);
    }
  };

  // Updated handleDraftContract to use confirmation dialog
  // const handleDraftContract = () => {
  //   if (artifact.contract_id) {
  //     setConfirmOpen(true);
  //   } else {
  //     createContract();
  //   }
  // };

  // Handlers for confirmation dialog
  // const handleConfirm = () => {
  //   setConfirmOpen(false);
  //   createContract();
  // };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  let buttons = null;
  if (true) {
    buttons = (
      <>
        {artifact.actions?.map((action) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSendFormRequest(action)}
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 2 }} />
                Processing...
              </>
            ) : (
              action.name
            )}
          </Button>
        ))}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          px: 0,
          pb: 0,
          maxHeight: "80vh",
          height: "100%",
          overflow: "auto",
        }}
      >
        <FormDisplay
          formConfig={formConfig}
          sendMessage={sendMessage}
          chatbotLoading={chatbotLoading || loading}
          conversationOver={false}
          setFormConfig={setFormConfig}
          handleFormUpdate={handleFormUpdate}
          setIsModified={setIsModified}
          inverseBackground={false}
          isSmall={true}
          highlightBlankFields={true}
        />
        {buttons}
        {/* Status Dialog */}
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          sx={{ p: 10, minWidth: 500 }}
        >
          <Alert severity={dialogColor} sx={{ p: 2 }}>
            <AlertTitle>{dialogTitle}</AlertTitle>
            <span dangerouslySetInnerHTML={dialogMessage} />
          </Alert>
        </Dialog>
      </Box>
    </>
  );
};

export default FormArtifact;
