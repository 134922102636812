import { axiosInstance } from "./general";
import { v4 as uuidv4 } from "uuid";
import qs from "qs";
// Function to generate a new Conversation ID
const getNewConversationId = () => uuidv4();

// Initialize conversationId at runtime
let conversationId = getNewConversationId();

// Function to reset Conversation ID
export const resetConversationId = () => {
  conversationId = getNewConversationId();
  console.log("Conversation ID Reset:", conversationId);
};

// Call resetConversationId on module load to ensure it's fresh
resetConversationId();

export const getChat = async (initParams, resetChat = null) => {
  try {
    const formData = new FormData();

    if (resetChat) {
      formData.append("reset_chat", resetChat);
      resetConversationId();
    }
    formData.append("init_params", JSON.stringify(initParams));
    formData.append("conversation_id", conversationId);
    const response = await axiosInstance.post(`/chat/get-chat`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching initial message:", error);
    throw error;
  }
};

export const sendMessageToChatbot = async (formData) => {
  try {
    const response = await axiosInstance.post(`/chat/chat`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const getCanvas = async (requestId, stepId) => {
  const response = await axiosInstance.post(`/chat/get-canvas`, {
    request_id: requestId,
    step_id: stepId,
  });
  return response.data;
};

/**
 * Search fields based on field_id and search query.
 * @param {string} fieldId - The ID of the field to search.
 * @param {string} searchQuery - The search string input by the user.
 * @returns {Promise<Object>} - The search results from the backend.
 */
export const searchField = async (fieldId, searchQuery) => {
  try {
    const response = await axiosInstance.get(`/misc/search-field`, {
      params: {
        field_id: fieldId,
        search: searchQuery,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    });
    console.log("Response: ", response.data);
    return response.data.results;
  } catch (error) {
    console.error("Error searching field:", error);
    throw error;
  }
};
