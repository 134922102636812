import { axiosInstance } from "./general";

export const fetchRequestDetails = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/requests/details`,
      {
        request_id: requestId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching request details:", error);
    throw error;
  }
};

// New: Fetch all requests with pagination, sorting, search, and status filter
export const fetchRequests = async (
  page,
  perPage,
  sortOrder = "desc",
  searchQuery = "",
  statusFilter = ""
) => {
  try {
    const params = {
      page,
      per_page: perPage,
      sort_order: sortOrder,
    };

    if (searchQuery) {
      params.search = searchQuery;
    }

    if (statusFilter) {
      params.status = statusFilter;
    }

    const response = await axiosInstance.get(`/requests`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching requests:", error);
    throw error;
  }
};

export const fetchAssignedRequests = async () => {
  const response = await axiosInstance.get(`/requests/assigned-requests`);
  return response.data;
};

// Optionally, fetch a single request
export const fetchRequestById = async (id) => {
  const response = await axiosInstance.get(`/requests/${id}`);
  return response.data;
};

// export const updateAgendaItemStatus = async (
//   requestId,
//   agendaItemId,
//   newStatus
// ) => {
//   const response = await axiosInstance.post(`/requests/update-agenda`, {
//     request_id: requestId,
//     agenda_item_id: agendaItemId,
//     new_status: newStatus,
//   });
//   return response.data;
// };

export const updateAgendaItem = async ({
  requestId,
  agendaItemId,
  newAssignedUsersEmails = null,
  newStatus = null,
  newDate = null,
}) => {
  const response = await axiosInstance.post(`/requests/update-agenda-item`, {
    request_id: requestId,
    agenda_item_id: agendaItemId,
    new_status: newStatus,
    new_assigned_users_emails: newAssignedUsersEmails,
    new_date: newDate,
  });
  return response.data;
};

export const getItemArtifacts = async (requestId, stepId) => {
  const response = await axiosInstance.post(`/requests/get-item-artifacts`, {
    request_id: requestId,
    step_id: stepId,
  });
  return response.data;
};

// export const updateAgendaItemDate = async (
//   requestId,
//   agendaItemId,
//   newDate
// ) => {
//   const response = await axiosInstance.post(`/requests/update-agenda`, {
//     request_id: requestId,
//     agenda_item_id: agendaItemId,
//     new_date: newDate,
//   });
//   return response.data;
// };

export const downloadRequestFile = async (requestId, filename) => {
  try {
    const response = await axiosInstance.get(
      `/requests/${requestId}/download/${filename}`,
      {
        withCredentials: true,
      }
    );
    return response.data.download_url;
  } catch (error) {
    console.error("Error fetching download URL:", error);
    throw error;
  }
};

// New function for viewing files
export const viewRequestFile = async (requestId, filename) => {
  try {
    const response = await axiosInstance.get(
      `/requests/${requestId}/view/${filename}`,
      {
        withCredentials: true,
      }
    );
    return response.data.view_url;
  } catch (error) {
    console.error("Error fetching view URL:", error);
    throw error;
  }
};

export const createERPContract = async (requestId, formConfig) => {
  try {
    const response = await axiosInstance.post(`/requests/create-erp-contract`, {
      request_id: requestId,
      form_config: formConfig,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating ERP contract:", error);
    throw error;
  }
};

export const sendComment = async (requestId, comment) => {
  const response = await axiosInstance.post(`/requests/send-comment`, {
    request_id: requestId,
    comment: comment,
  });
  return response.data;
};
