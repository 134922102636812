const toCamelCase = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export { toCamelCase };

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import FindInPageRoundedIcon from "@mui/icons-material/FindInPageRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import QuizIcon from "@mui/icons-material/Quiz";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import LibraryAddCheckRoundedIcon from "@mui/icons-material/LibraryAddCheckRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const translateOptions = (options) => {
  return options.map((option) => {
    let iconComponent;

    switch (option.icon) {
      case "ShoppingCartIcon":
        iconComponent = <ShoppingCartIcon color={option?.color || "error"} />;
        break;
      case "QuizIcon":
        iconComponent = <QuizIcon color={option?.color || "warning"} />;
        break;
      case "StoreIcon":
        iconComponent = <StoreIcon color={option?.color || "success"} />;
        break;
      case "PeopleRoundedIcon":
        iconComponent = (
          <PeopleRoundedIcon color={option?.color || "success"} />
        );
        break;
      case "FindInPageRoundedIcon":
        iconComponent = (
          <FindInPageRoundedIcon color={option?.color || "warning"} />
        );
        break;
      case "ContactPageRoundedIcon":
        iconComponent = (
          <ContactPageRoundedIcon color={option?.color || "warning"} />
        );
        break;
      case "AttachFileRoundedIcon":
        iconComponent = (
          <AttachFileRoundedIcon color={option?.color || "info"} />
        );
        break;
      case "ReceiptLongRoundedIcon":
        iconComponent = (
          <ReceiptLongRoundedIcon color={option?.color || "error"} />
        );
        break;
      case "MailOutlineRoundedIcon":
        iconComponent = (
          <MailOutlineRoundedIcon color={option?.color || "info"} />
        );
        break;
      case "BusinessCenterRoundedIcon":
        iconComponent = (
          <BusinessCenterRoundedIcon color={option?.color || "error"} />
        );
        break;
      case "LibraryAddCheckRoundedIcon":
        iconComponent = (
          <LibraryAddCheckRoundedIcon color={option?.color || "success"} />
        );
        break;
      case "CheckCircleIcon":
        iconComponent = <CheckCircleIcon color={option?.color || "success"} />;
        break;
      case "CancelIcon":
        iconComponent = <CancelIcon color={option?.color || "error"} />;
        break;
      default:
        iconComponent = null;
    }
    return { ...option, icon: iconComponent };
  });
};
