import React, { useMemo, useRef, useEffect } from "react";
import {
  List,
  ListItemText,
  Box,
  Typography,
  Chip,
  ListItemButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";

import debounce from "lodash.debounce";
import { toCamelCase } from "../../utils/formatting";
import Grid from "@mui/material/Grid2";

function getColorFromStringHash(str) {
  const colors = ["info", "secondary", "warning", "error", "success"];
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % colors.length;
  return colors[index];
}

const RequestsListPage = ({
  requests,
  user,
  searchQuery,
  handleSearch,
  statusFilter,
  handleStatusFilter,
  sortOrder,
  changeSortOrder,
  loading,
  currentPage,
  totalPages,
  setCurrentPage,
  // setPerPage,
}) => {
  const navigate = useNavigate();

  const containerRef = useRef(null);
  const formattedRequests = Object.entries(requests).map(([id, request]) => ({
    id,
    ...request,
  }));

  const handleSelectRequest = (request) => {
    navigate(`/dashboard/request/${request.id}`);
  };

  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    const newSortOrder = selectedSort === "newest" ? "desc" : "asc";
    changeSortOrder(newSortOrder);
  };

  // Debounced Search Handler
  const debouncedSearch = useMemo(
    () => debounce((value) => handleSearch(value), 500),
    [handleSearch]
  );

  const handleSearchInputChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Search and Filter Bar */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mt: 4,
          px: 4,
          py: 1,
          alignItems: "center",
          position: "sticky",
          top: 0,
          backgroundColor: "background.paper",
          zIndex: 1,
        }}
      >
        {/* Search Bar */}
        <TextField
          label="Search Requests"
          variant="outlined"
          defaultValue={searchQuery}
          onChange={handleSearchInputChange}
          sx={{
            flex: 1,
            minWidth: 200,
            borderRadius: 2,
            backgroundColor: "background.dark",
          }}
        />

        {/* Status Filter */}
        <FormControl variant="outlined" sx={{ minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => handleStatusFilter(e.target.value)}
            label="Status"
            sx={{
              borderRadius: 2,
              backgroundColor: "background.dark",
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="complete">Complete</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </Select>
        </FormControl>

        {/* Sort Select */}
        <FormControl variant="outlined" sx={{ minWidth: 150 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOrder === "desc" ? "newest" : "oldest"}
            onChange={handleSortChange}
            label="Sort By"
            sx={{
              borderRadius: 2,
              backgroundColor: "background.dark",
            }}
          >
            <MenuItem value="newest">Newest</MenuItem>
            <MenuItem value="oldest">Oldest</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Header for the list */}
      <Grid
        container
        spacing={2}
        sx={{
          px: 4,
          py: 1,
          mt: 1,
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          zIndex: 1,
          alignItems: "center",
        }}
      >
        <Grid size={4}>
          <Typography variant="subtitle2">Requester & Title</Typography>
        </Grid>
        <Grid size={2}>
          <Typography variant="subtitle2">Assigned Users</Typography>
        </Grid>
        <Grid size={3}>
          <Typography variant="subtitle2" textAlign="center">
            Approval Status
          </Typography>
        </Grid>
        {/* <Grid size={1.5}>
          <Typography variant="subtitle2" textAlign="right">
            Updated At
          </Typography>
        </Grid> */}
        <Grid size={2}>
          <Typography variant="subtitle2" textAlign="right">
            Submitted At
          </Typography>
        </Grid>
      </Grid>

      {/* Scrollable Requests List */}
      <Box ref={containerRef} sx={{ flex: 1, overflowY: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {formattedRequests.length > 0 ? (
              formattedRequests.map((request) => (
                <ListItemButton
                  key={request.id}
                  onClick={() => handleSelectRequest(request)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "background.dark",
                      cursor: "pointer",
                    },
                    px: 4,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    sx={{ height: "100%", width: "100%" }}
                  >
                    <Grid size={4}>
                      <ListItemText
                        primary={
                          <Typography noWrap variant="subtitle1">
                            <strong>{request.requester_name}, </strong>
                            {request.title}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid size={2}>
                      <ListItemText
                        primary={
                          <Typography noWrap color="text.secondary">
                            {request.assigned_users?.length === 1
                              ? request.assigned_users[0].name
                              : request.assigned_users
                                  ?.map((u) => u.name.split(" ")[0])
                                  .join(", ")}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid size={3}>
                      <Chip
                        label={toCamelCase(request.current_approval_title)}
                        variant="contained"
                        sx={{ width: "100%" }}
                        color={getColorFromStringHash(
                          request.current_approval_title
                        )}
                      />
                    </Grid>
                    {/* <Grid size={1.5}>
                      <ListItemText
                        primary={
                          isValid(new Date(request.updated_at))
                            ? format(
                                new Date(request.updated_at),
                                "MMM d, hh:mm "
                              )
                            : "N/A"
                        }
                        sx={{ textAlign: "right" }}
                      />
                    </Grid> */}
                    <Grid size={2}>
                      <ListItemText
                        primary={
                          isValid(new Date(request.submitted_at))
                            ? format(
                                new Date(request.submitted_at),
                                "MMM d, yyyy"
                              )
                            : "N/A"
                        }
                        sx={{ textAlign: "right" }}
                      />
                    </Grid>
                  </Grid>
                </ListItemButton>
              ))
            ) : (
              <Box sx={{ width: "100%", textAlign: "center", mt: 4 }}>
                <Typography variant="h6">No requests found.</Typography>
              </Box>
            )}
          </List>
        )}
      </Box>

      {/* Fixed Pagination Component */}
      <Box
        sx={{
          py: 2,
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.dark",
          zIndex: 1,
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default RequestsListPage;
