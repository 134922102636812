import React, { useState, useMemo } from "react";
import { Box, Button, Fade, Modal, Typography } from "@mui/material";
import TableArtifact from "./TableArtifact";
import FormArtifact from "./FormArtifact";

import BackupTableRoundedIcon from "@mui/icons-material/BackupTableRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "95%",
  bgcolor: "background.dark",
  boxShadow: 24,
  p: 2,
  overflow: "none",
};

const Artifact = ({
  artifact,
  sendMessage,
  chatbotLoading,
  embedInModal = false,
}) => {
  // Check if artifact is null, an empty list, or an empty dictionary
  if (
    !artifact ||
    (Array.isArray(artifact) && artifact.length === 0) ||
    (typeof artifact === "object" && Object.keys(artifact).length === 0) ||
    !artifact?.type
  ) {
    return null;
  }

  const [open, setOpen] = useState(false);

  const artifactContent = useMemo(() => {
    if (artifact?.type === "table") {
      return (
        <TableArtifact
          artifact={artifact}
          sendMessage={sendMessage}
          chatbotLoading={chatbotLoading}
          setModalOpen={setOpen}
        />
      );
    }
    if (artifact?.type === "form") {
      return (
        <FormArtifact
          artifact={artifact}
          sendMessage={sendMessage}
          chatbotLoading={chatbotLoading}
        />
      );
    }
    return null;
  }, [artifact, sendMessage, chatbotLoading]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // If not embedding in a modal, just render the artifact content
  if (!embedInModal) {
    return artifactContent;
  }

  // If embedInModal is true, render a button to open the modal, and show the artifact inside the modal
  return (
    <>
      {artifact?.title ? (
        <Button onClick={handleOpen} color="primary" variant="contained">
          <Typography>{artifact?.title}</Typography>
        </Button>
      ) : (
        <Button
          onClick={handleOpen}
          color="error"
          sx={{
            width: 128,
            height: 64,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            my: 1,
          }}
        >
          {artifact?.type === "table" ? (
            <BackupTableRoundedIcon />
          ) : artifact?.type === "form" ? (
            <ChecklistRoundedIcon />
          ) : null}
          <Typography variant="caption">
            {artifact?.type === "table"
              ? "View Table"
              : artifact?.type === "form"
              ? "View Form"
              : null}
          </Typography>
        </Button>
      )}

      <Modal open={open} onClose={handleClose} closeAfterTransition>
        {/* <Fade in={open}> */}
        <Box sx={modalStyle}>{artifactContent}</Box>
        {/* </Fade> */}
      </Modal>
    </>
  );
};

export default Artifact;
