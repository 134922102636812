import React, { useState, useEffect, useRef, allback } from "react";
import {
  Box,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import FileAttachments from "./Files/FileAttachments";
import toolRunningMessages from "../../utils/toolRunningMessages.json";

const Chatbot = ({
  user,
  messages,
  conversationOver,
  options,
  setOptions,
  chatbotLoading,
  chatbotFiles,
  setChatbotFiles,
  selectedFiles,
  setSelectedFiles,
  inputRef,
  sendMessage,
}) => {
  const [input, setInput] = useState("");
  const [dragging, setDragging] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const dragCounter = useRef(0);

  const theme = useTheme();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Drag and Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    dragCounter.current = 0;

    const newFiles = Array.from(e.dataTransfer.files).filter(
      (file) => file.type === "application/pdf"
    );

    // Check for duplicate file names
    const existingFileNames = selectedFiles.map((file) => file.name);
    const duplicates = newFiles.filter((file) =>
      existingFileNames.includes(file.name)
    );

    if (duplicates.length > 0) {
      setDuplicateFiles(duplicates.map((file) => file.name));
      setOpenDuplicateModal(true);
    }

    // Filter out duplicates before adding
    const uniqueFiles = newFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    if (selectedFiles.length + uniqueFiles.length > 3) {
      setOpenModal(true);
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
    setDuplicateFiles([]);
  };

  useEffect(() => {
    if (!chatbotLoading && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // Adjust the delay as needed
    }
  }, [chatbotLoading]);

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        mx: 2,
        flexDirection: "column",
        pt: 2,
        overflow: "hidden",
        position: "relative",
        border: dragging ? `2px dashed ${theme.palette.primary.main}` : "none",
        backgroundColor: dragging ? theme.palette.action.hover : "inherit",
        transition: "background-color 0.3s, border 0.3s",
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <MessageList
        user={user}
        messages={messages}
        toolRunningMessages={toolRunningMessages}
        theme={theme}
        messagesEndRef={messagesEndRef}
        chatbotLoading={chatbotLoading}
        sendMessage={sendMessage}
        submittedSuccessfully={false} // Adjust as needed
        options={options}
        setOptions={setOptions}
      />

      {conversationOver ? (
        <Alert severity="success" variant="filled" sx={{ mb: 2, mt: 2 }}>
          <AlertTitle>Conversation Over</AlertTitle>
          Your conversation with the chatbot is over. You can close this window
          now.
        </Alert>
      ) : (
        <>
          <FileAttachments
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            theme={theme}
          />

          <MessageInput
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            chatbotLoading={chatbotLoading}
            theme={theme}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            conversationOver={conversationOver}
            chatbotFiles={chatbotFiles}
            setChatbotFiles={setChatbotFiles}
            inputRef={inputRef} // Pass the ref to focus the input
          />
        </>
      )}
      {/* Modal for File Upload Limit Exceeded */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          You can only upload up to 3 files at a time. Please upload new files
          in a separate message.
        </DialogTitle>
      </Dialog>
      {/* Modal for Duplicate Files */}
      <Dialog open={openDuplicateModal} onClose={handleCloseDuplicateModal}>
        <DialogTitle>The following file(s) are already attached:</DialogTitle>
        <DialogContent>
          <ul>
            {duplicateFiles.map((fileName, idx) => (
              <li key={idx}>{fileName}</li>
            ))}
          </ul>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Chatbot;
