import React, { useState, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import FlowDiagram from "../../../components/Diagram/FlowDiagram";
import { useTheme } from "@mui/material/styles";

const Overview = ({
  user,
  steps,
  request,
  allUsers,
  getRequestDetails,
  sendMessage,
  chatbotLoading,
  updateCanvas,
  canvas,
  activeNode,
  handleNodeClick,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "background.dark",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 1,
          backgroundColor: "background.default",
          height: "100%",
        }}
      >
        <FlowDiagram
          user={user}
          allUsers={allUsers}
          steps={steps}
          request={request}
          theme={theme}
          handleNodeClick={handleNodeClick}
          getRequestDetails={getRequestDetails}
          sendMessage={sendMessage}
          activeNode={activeNode}
        />
      </Paper>
    </Box>
  );
};

export default Overview;
