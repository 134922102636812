import React from "react";
import { Box, TextField, Button, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const MessageInput = ({
  input,
  setInput,
  sendMessage,
  formLoading,
  chatbotLoading,
  theme,
  selectedFiles,
  setSelectedFiles,
  chatbotFiles,
  setChatbotFiles,
}) => {
  const handleSend = async () => {
    console.log("Handling send!!!");
    if (input.trim() && !chatbotLoading) {
      sendMessage(input, selectedFiles);
      setInput("");
      console.log("Selected files AAA: ", selectedFiles);
      // add selected files to all files
      setChatbotFiles([...chatbotFiles, ...selectedFiles]);
      setSelectedFiles([]);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      console.log("Handling enter!!!");
      handleSend();
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
      <input
        accept="application/pdf"
        style={{ display: "none" }}
        id="file-upload"
        multiple
        type="file"
        onChange={(e) =>
          setSelectedFiles([
            ...selectedFiles,
            ...Array.from(e.target.files).filter(
              (file) => file.type === "application/pdf"
            ),
          ])
        }
        disabled={selectedFiles.length >= 1 || formLoading || chatbotLoading}
      />
      <label htmlFor="file-upload">
        {/* <IconButton
          component="span"
          disabled={selectedFiles.length >= 1 || formLoading || chatbotLoading}
        >
          <AttachFileIcon />
        </IconButton> */}
      </label>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Message Optimized AI"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        multiline
        maxRows={5}
        InputProps={{
          endAdornment: (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              disabled={!input.trim() || chatbotLoading}
              sx={{
                minWidth: 0,
                padding: 1,
                borderRadius: "50%",
              }}
            >
              <SendIcon />
            </Button>
          ),
          sx: {
            backgroundColor: theme.palette.background.dark,
            border: "none",
            width: "100%",
            margin: "0 auto",
            py: 1,
            px: 2,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "32px",
          },
          "& .MuiInputBase-root": {
            mx: 0,
          },
        }}
      />
    </Box>
  );
};

export default MessageInput;
